@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

html {
  overflow-y: scroll;
}

body,
html {
  min-height: 100vh;
  --tina-color-primary-light: #8883FF;
  --tina-color-primary: #6343FF;
  --tina-color-primary-dark: #3011A3;
}

body {
  display: flex;
}

#__next {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  background-color: #f7fafc;
}
